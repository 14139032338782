<template>
<v-container>
  <!-- BOC:[breadcrumbs] -->
  <ABreadcrumb :items="breadcrumbs"></ABreadcrumb>
  <!-- EOC -->
  <!-- BOC:[form] -->
  <BreadForm
    :role="role"
    :model="model"
    :url="newUrl"
    action="add"
    :callbackSuccess="callbackSuccess"
  ></BreadForm>
  <!-- EOC -->
</v-container>
</template>

<script>
  //BOC:[model]
  import models from '@/models/index'
  //EOC
  import BreadForm from '@/components/Bread/BreadForm'
  import { mapState } from 'vuex'
  export default {
    metaInfo() {
      return {
        title:`New ${this.model.name.singular} | Hamochi`,
      }
    },
    components:{
      BreadForm,
    },
    computed: mapState({
      //
    }),
    props:[
      'role',
    ],
    data: () => ({
      //BOC:[model]
      model: {},
      //EOC
      //BOC:[breadcrumbs]
      breadcrumbs: [],
      //EOC
      //BOC:[url]
      newUrl:null,
      //EOC
    }),
    created() {
      //BOC:[model]
      this.model = models[this.$route.params.object]
      //EOC
      //BOC:[guard]
      if(!this.model[this.role].add) {
        this.$router.replace(this.$_getRouteBrowse(this.role,this.model.key))
        return
      }
      //EOC
      //BOC:[breadcrumbs]
      this.breadcrumbs.push({
        text:'Dashboard',
        to:{name:'Page'+this.role+'Dashboard'},
        exact:true,
      })
      //
      this.breadcrumbs.push({
        text:this.model.name.plural,
        to:this.$_getRouteBrowse(this.role,this.model.key),
        exact:true,
      })
      //
      this.breadcrumbs.push({
        text:`New ${this.model.name.singular}`,
        to:this.$_getRouteAdd(this.role,this.model.key),
        exact:true,
      })
      //EOC
      //BOC:[url]
      this.newUrl = (this.url) ? this.url : `${this.$api.servers[this.role.toLowerCase()]}/v1/bread/${this.$route.params.object}/add`
      //EOC
    },
    mounted() {
      //
    },
    methods: {
      callbackSuccess(resp) {
        this.$router.push(this.$_getRouteRead(this.role,this.model.key,resp.id,resp))
      },
    }
  }
</script>